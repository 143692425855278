import React, {useState} from "react"

export const ConfigContext = React.createContext({})

let id = 0;

export default function ConfigProvider({children}) {
    const [config, setConfig] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    window.addEventListener('resize', () => {
        window.clearTimeout(id)
        id = window.setTimeout(() => {
            setConfig({
                ...config,
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }, 10)
    })

    return <ConfigContext.Provider value={{config}}>
        {children}
    </ConfigContext.Provider>
}
