import React, {useReducer, useRef} from "react";
import {Sprite, useTick} from "@inlet/react-pixi";

const reducer = (_, {data}) => data

export default function Cursor({width, height}) {

    const [motion, update] = useReducer(reducer)
    const iter = useRef(0)

    useTick(delta => {
        const i = (iter.current += 0.01 * delta)
        update({
            type: 'update',
            data: {
                x: Math.sin(i) * width / 4,
                y: Math.cos(i / 1.5) * height / 4,
                rotation: Math.sin(i) * Math.PI,
                anchor: Math.sin(i / 2) / 2,
            },
        })
    })
    return (
        <Sprite
            image="./cursor.png"
            {...motion}
        />
    )
}
