import React, {useContext, useState} from "react";
import {ConfigContext} from "./providers/ConfigProvider";

export default function Background({videos}) {
    const configProvider = useContext(ConfigContext)
    const config = configProvider.config

    const [dims, setDims] = useState({})

    return <>
        {videos?.map((video, index) => {
            const dim = dims[video]

            let width
            let height
            if (dim) {
                // video is wider than the screen
                if (dim.width / dim.height > config.width / config.height) {
                    width = 'auto'
                    height = Math.max(config.height, dim.height)
                } else {
                    width = Math.max(config.width, dim.width)
                    height = 'auto'
                }
            }

            return <video
                id={`background-${index}`}
                key={index}
                autoPlay
                muted
                loop
                onLoadedMetadata={(e) => {
                    dims[video] = {
                        width: e.target.videoWidth,
                        height: e.target.videoHeight
                    }
                    setDims({...dims})
                }}
                style={{
                    display: 'none',
                    position: 'fixed',
                    width: width,
                    height: height,
                    zIndex: -1
                }}>
                <source src={`./${video}`} type="video/mp4"/>
            </video>
        })}
    </>
}
