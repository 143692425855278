import React, {useContext, useState} from "react";
import {
    Container, Graphics, Sprite,
    Stage, Text, withFilters,
} from "@inlet/react-pixi";
import Cursor from "./Cursor";
import {ConfigContext} from "./providers/ConfigProvider";
import {AsciiFilter} from "@pixi/filter-ascii";
import {AdjustmentFilter} from "@pixi/filter-adjustment";
import {TextStyle} from "pixi.js";

export default function MainStage() {
    const configProvider = useContext(ConfigContext)
    const config = configProvider.config
    const [mouseX, setMouseX] = useState(config.width / 2)
    const [mouseY, setMouseY] = useState(config.height / 2)

    const mask = React.useRef()

    let width
    let height
    // video is wider than the screen
    if (1280 / 720 > config.width / config.height) {
        height = Math.max(config.height, 720)
        width = height * 1280 / 720
    } else {
        width = Math.max(config.width, 1280)
        height = width / 1280 * 720
    }

    const FiltersBack = withFilters(Container, {
        ascii: AsciiFilter,
    })

    const FiltersMask = withFilters(Container, {
        adjust: AdjustmentFilter
    })

    const Content = () => <>
        <Sprite
            source={document.querySelector('#background-0')}
            width={width}
            height={height}
        />
        <Text
            text="Contact info@trex.gr for more"
            anchor={0}
            x={10}
            y={config.height - 100}
            style={
                new TextStyle({
                    align: 'center',
                    fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
                    fontSize: 40,
                    fontWeight: 400,
                    fill: ['#c7ecae', '#7ce86b'], // gradient
                    letterSpacing: 5,
                    dropShadow: true,
                    dropShadowColor: '#3fcc27',
                    dropShadowBlur: 2,
                    dropShadowAngle: Math.PI / 6,
                    dropShadowDistance: 4,
                    wordWrap: true,
                    wordWrapWidth: config.width - 10,
                })
            }
        />
    </>

    return <>
        <Stage
            onMouseMove={e => {
                setMouseX(e.clientX)
                setMouseY(e.clientY)
            }}
            style={{position: 'fixed'}}
            options={{
                backgroundAlpha: 0.6
            }}
            width={config.width}
            height={config.height}>
            <Container
                x={0}
                y={0}>
                <FiltersBack ascii={{size: 14}}>
                    <Content/>
                </FiltersBack>
                <Container mask={mask.current}>
                    <FiltersMask adjust={{alpha: 0.6, gamma: 0.4}}>
                        <Content/>
                    </FiltersMask>
                </Container>
                <Container
                    ref={mask}
                >
                    <Graphics
                        x={mouseX}
                        y={mouseY}
                        preventRedraw={true}
                        draw={g => {
                            g.beginFill(0x000000);
                            g.drawCircle(0, 0, 200);
                            g.endFill();
                        }}
                    />
                </Container>
            </Container>
            <Container
                x={mouseX}
                y={mouseY}>
                <Cursor width={5} height={5}/>
            </Container>
        </Stage>
    </>
}
