import './App.css';
import React, {useEffect, useState} from 'react';
import Background from "./components/Background";
import MainStage from "./components/MainStage";

function App() {

    const [stage, setStage] = useState(<></>);

    useEffect(() => {
        setStage(<MainStage/>)
    }, [])

    return (
        <>
            <Background videos={['space.mp4']}/>
            <div style={{position: 'fixed', top: 0, zIndex: 0}}>
                {stage}
            </div>
        </>
    );
}

export default App;
